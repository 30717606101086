<script>
import Layout from '@layouts/auth'
import { authMethods } from '@state/helpers'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validateCPF from '@utils/validate-cpf'

/**
 * Login component
 */
export default {
    components: { Layout },
    mixins: [swalFeedback],
    data() {
        return {
            login: '',
            loginMask: '',
            password: '',
            tryingToLogIn: false,
            form: {
                touch: false,
                login: {
                    error: '',
                    valid: null,
                }
            },
        }
    },
    computed: {
        i18nPassword() {
            return this.getI18n('COMMON', 'password')
        },
        i18nLogin() {
            return this.getI18n('COMMON', 'login')
        },
        i18nForgotYourPassword() {
            return this.getI18n('COMMON', 'forgot_your_password')
        },
        placeholders() {
            return process.env.NODE_ENV === 'production'
                ? {}
                : {
                      login: 'Use "admin" to log in with the mock API',
                      password: 'Use "password" to log in with the mock API',
                  }
        },
    },
    watch: {
        login(newValue) {
            const onlyDigits = newValue.replace(/\D/g, '');
            if (newValue.length === 4 || onlyDigits.length === 11) {
                this.loginMask = /^([0-9]{3}\.|[0-9]{4})$/.test(newValue.substring(0, 4)) ? '###.###.###-##' : ''
            }

            if (newValue.length < 4) this.loginMask = '';

            if (this.form.touch) this.validateForm();
        },
    },
    methods: {
        ...authMethods,
        validateForm() {
            this.form.touch = true;

            if (!this.loginMask && !(/^[\w0-9.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.login))) {
                this.form.login.error = 'Por favor, insira um e-mail válido.';
                this.form.login.valid = false;

                return false;
            }

            if (this.loginMask && !validateCPF(this.login)) {
                this.form.login.error = 'Por favor, insira um CPF válido.';
                this.form.login.valid = false;

                return false;
            }

            this.form.login.error = '';
            this.form.login.valid = true;

            return true;
        },
        // Try to log the user in with the email or CPF
        // and password they provided.
        tryToLogIn() {
            this.tryingToLogIn = true

            if (!this.validateForm()) {
                this.tryingToLogIn = false;
                return;
            }

            return this.logIn({
                login: this.login,
                password: this.password,
            })
                .then((response) => {
                    this.getDefaultDashboardRole()
                    this.tryingToLogIn = false
                    const canGetDashboardResume = this.$can('GET', 'DASHBOARDRESUME')
                    if (response.tenant_id === "admin") {
                        this.$router.push({ name: 'tenant' })
                    }
                    // else if (response.user && response.user.roles && response.user.roles.length > 0 && ['cliente', 'cliente extração'].includes(response.user.roles[0].name)) {
                    else if (!canGetDashboardResume) {
                        this.$router.push(
                            { name: 'photoGallery' }
                        )
                    }
                    else {
                        this.$router.push(
                            { name: 'home' }
                        )
                    }
                })
                .catch((error) => {
                    this.tryingToLogIn = false
                    const statusCode = error.status
                    if (statusCode === 401 || statusCode === 422) {
                        this.warningFeedback('Login', this.getI18n('ERROR_CODES', 'invalid_login'))
                    } else {
                        this.dangerFeedback('Login', error ? error.data.message : this.getI18n('ERROR_CODES', 'invalid_login'))
                    }
                })
        },
    },
}
</script>

<template>
    <Layout>
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="d-flex">
                    <div class="card-body p-3">
                        <div class="text-center w-100 m-auto">
                            <a href="/">
                                <span>
                                    <img
                                        class="img-fluid"
                                        src="@assets/images/logo-login.png"
                                        alt
                                        height="116"
                                    />
                                </span>
                            </a>
                        </div>
                        <b-form
                            class="form-access"
                            @submit.prevent="tryToLogIn"
                        >
                            <b-form-group
                                id="input-group-1"
                                label="E-mail/CPF"
                                label-for="input-1"
                            >
                                <b-form-input
                                    id="input-1"
                                    v-model="login"
                                    v-mask="loginMask"
                                    type="text"
                                    required
                                    :state="form.login.valid"
                                    :disabled="tryingToLogIn"
                                    autocomplete="login"
                                    placeholder="E-mail ou CPF"
                                    aria-describedby="input-1-feedback"
                                ></b-form-input>

                                <b-form-invalid-feedback v-show="!form.login.valid" id="input-1-feedback" style="color: #d7364e;">
                                    {{ form.login.error }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group
                                id="input-group-2"
                                :label="i18nPassword"
                                label-for="input-2"
                            >
                                <b-form-input
                                    id="input-2"
                                    v-model="password"
                                    type="password"
                                    required
                                    :disabled="tryingToLogIn"
                                    autocomplete="current-password"
                                    :placeholder="i18nPassword"
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group id="button-group" class="mt-4">
                                <b-button
                                    type="submit"
                                    variant="primary"
                                    class="btn-block"
                                    :disabled="tryingToLogIn"
                                >
                                    <template v-if="tryingToLogIn">
                                        <b-spinner small />
                                    </template>
                                    <template v-else>
                                        <span>
                                            {{ i18nLogin }}
                                        </span>
                                    </template>
                                </b-button>
                            </b-form-group>
                        </b-form>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->

                <div class="row">
                    <div class="col-12 text-center">
                        <p>
                            <router-link
                                v-slot="{ navigate }"
                                to="/forget-password"
                                custom
                            >
                                <a
                                    class="text-white"
                                    style="cursor: pointer"
                                    role="link"
                                    @click="navigate"
                                    >
                                {{ i18nForgotYourPassword }}?
                                </a>
                            </router-link>
                        </p>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </Layout>
</template>
