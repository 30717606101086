
export default function validateCPF(value) {
    // Remove caracteres não numéricos
    const cpf = value.replace(/\D/g, '');

    // Verifica se o CPF tem 11 dígitos e não é formado por números repetidos
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
        return false;
    }

    // Calcula o primeiro e o segundo dígito verificador
    const calcDigito = (base) => {
        const soma = base.reduce((acc, num, i) => acc + num * (base.length + 1 - i), 0);
        const resto = soma % 11;
        return resto < 2 ? 0 : 11 - resto;
    };

    const nums = cpf.split("").map(Number);
    const digito1 = calcDigito(nums.slice(0, 9));
    const digito2 = calcDigito(nums.slice(0, 10));

    // Verifica se os dígitos verificadores estão corretos
    return digito1 === nums[9] && digito2 === nums[10];
}
